import React from 'react';

function CheckoutView() {
  return (
    <div data-testid="store-cart-checkout">
      Checkout
    </div>
  );
}

export default CheckoutView;