import React from 'react';
import UserProfile from '../../features/myAccount/userProfile/userProfile';

function MyAccount() {
  return (
    <>
      <div>MyAccount</div>
      <UserProfile/>
    </>
  );
}

export default MyAccount;
