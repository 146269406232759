import React from 'react';
import LoginForm from '../../features/authentication/logInForm/LoginForm';

function Login() {
  return (
    <LoginForm />
  );
}

export default Login;
