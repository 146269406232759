import React from 'react';

function Product() {
  return (
    <div data-testid="store-product-details">
      Product Details
    </div>
  );
}

export default Product;