import React from 'react';
import RegistrationSuccessfulResponse from '../../features/authentication/registrationForm/RegistrationSuccessfulResponse'

function RegistrationSuccessful() {
  return (
    <RegistrationSuccessfulResponse />
  );
}

export default RegistrationSuccessful;
